$direction: ltr;
$opposite-direction: rtl;

$start-direction: left;
$end-direction: right;

$transform-direction: 1;

@mixin rtl-mirrored {
}

@mixin padding-direction($top, $end, $bottom, $start, $important: false) {
  @if $important {
    padding: $top $end $bottom $start !important;
  } @else {
    padding: $top $end $bottom $start;
  }
}

@mixin margin-direction($top, $end, $bottom, $start, $important: false) {
  @if $important {
    margin: $top $end $bottom $start !important;
  } @else {
    margin: $top $end $bottom $start;
  }
}
