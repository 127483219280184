%zen {
  .zen & {
    display: none;
  }
}

body.zenable.zen {
  #top {
    justify-content: center;
  }

  .puzzle__moves.areplay {
    display: none;
  }
}

#friend_box,
.site-title-nav,
.site-buttons {
  @extend %zen;
}
