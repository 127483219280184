@import '../pgn-viewer/lichess-pgn-viewer';
@import './common/dir/ltr';
@import './common/theme/light';
@import './common/lichess';
//@import './analyse/analyse.base';
@import './ceval/ceval';
@import './tree/tree';
@import './puzzle/puzzle';
@import './common/form/cmn-toggle';

cg-board {
  background-image: url('../svgs/board.svg');
}

coords.files {
  height: 19px !important;
}
coords.ranks {
  width: 19px !important;
}

.white.pawn {background-image:url('../svgs/white-pawn.svg') !important;}
.white.bishop {background-image:url('../svgs/white-bishop.svg') !important;}
.white.knight {background-image:url('../svgs/white-knight.svg') !important;}
.white.rook {background-image:url('../svgs/white-rook.svg') !important;}
.white.queen {background-image:url('../svgs/white-queen.svg') !important;}
.white.king {background-image:url('../svgs/white-king.svg') !important;}

.black.pawn {background-image:url('../svgs/black-pawn.svg') !important;}
.black.bishop {background-image:url('../svgs/black-bishop.svg') !important;}
.black.knight {background-image:url('../svgs/black-knight.svg') !important;}
.black.rook {background-image:url('../svgs/black-rook.svg') !important;}
.black.queen {background-image:url('../svgs/black-queen.svg') !important;}
.black.king {background-image:url('../svgs/black-king.svg') !important;}

coords {
  font-size: 13px !important;
}


.orientation-white .files coord:nth-child(2n + 1),
.orientation-white .ranks coord:nth-child(2n),
.orientation-black .files coord:nth-child(2n),
.orientation-black .ranks coord:nth-child(2n + 1) {
  color: #E8EDF9 !important;
}

.orientation-white .files coord:nth-child(2n),
.orientation-white .ranks coord:nth-child(2n + 1),
.orientation-black .files coord:nth-child(2n + 1),
.orientation-black .ranks coord:nth-child(2n) {
  color: #B7C0D8 !important;
}
