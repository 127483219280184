.lpv {
  &__fbt {
    background: none;
    border: none;
    outline: none;
    color: $lpv-font;
    align-items: normal;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1em;
    line-height: 1.5;
    text-decoration: none;

    @media (hover: hover) {
      &:hover:not(.disabled):not([disabled]) {
        background: mix($lpv-accent, $lpv-bg, 75%);
        color: #fff;
      }
    }

    &.active {
      background: $lpv-accent !important;
      color: #fff;
    }

    &.disabled,
    &[disabled] {
      opacity: 0.4;
      cursor: default;
    }
  }
}
