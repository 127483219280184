button,
input,
optgroup,
select,
textarea {
  font: inherit;
  color: $c-font;
  outline-color: $c-primary;
}

option,
optgroup {
  background: $c-bg-box-opaque;
  color: $c-font-clear;
}

input,
textarea,
select {
  @extend %box-radius;

  background: $c-bg-input;
  border: $border;
  padding: 0.6em 1em;
}

textarea {
  overflow: auto;
  resize: vertical;
  padding: 0.8em 1em;
  unicode-bidi: plaintext;
}

button,
a {
  cursor: pointer;
}

::placeholder {
  color: $c-font-dim;
}

.copyable {
  background: $c-page-input;
  color: $c-font-clear;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  border: $border;
  -webkit-text-fill-color: $c-font;
  -webkit-box-shadow: 0 0 0px 1000px mix($c-good, $c-bg-input, 10%) inset;
}
