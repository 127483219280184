.is::before,
[data-icon]::before,
.is-after::after {
  @extend %data-icon;
}

.is.text::before,
.text[data-icon]::before {
  @include margin-inline-end(0.4em);
}

.is-green::before {
  color: $c-good;
}

.is-red::before {
  color: $c-bad;
}

.is-gold::before {
  color: $c-brag;
}

// mirror arrow icons in RTL

@if $direction == 'rtl' {
  [data-icon=''],
  [data-icon=''],
  [data-icon=''],
  [data-icon=''],
  [data-icon=''],
  [data-icon=''],
  [data-icon=''],
  [data-icon=''] {
    &:not(.no-mirror)::before {
      display: inline-block;
      transform: scaleX(-1);
    }
  }
}
