.lpv {
  &__player {
    font-size: 0.8em;
    background: $lpv-bg-player;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 1em;
    // justify-content: stretch;
    &--bottom {
      border-bottom: 1px solid $lpv-border;
      .lpv--controls-false & {
        border-bottom: none;
      }
    }
    &__person {
      flex: 1 1 auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1ch;
      color: $lpv-font;
      text-decoration: none;
    }
    &__title {
      font-weight: bold;
    }
    &__clock {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-family: monospace;
      font-size: 1.4em;
      font-weight: bold;
      &.active {
        color: $lpv-accent;
      }
    }
  }
}
