.button {
  @extend %roboto-fat, %button-shadow, %box-radius;

  background: $c-primary;
  text-transform: uppercase;
  padding: 0.8em 1em;
  border: none;
  cursor: pointer;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  display: inline-block;

  @include transition;

  &,
  &:visited,
  &:focus {
    color: $c-primary-over;
  }

  &:not(.disabled):hover {
    @extend %button-raised-shadow;

    color: $c-primary-over;
    background: lighten($c-primary, 7);
  }

  &:not(.disabled):focus {
    @extend %focus-shadow;
  }

  &-no-upper {
    text-transform: none;
  }

  &-thin {
    padding: 0.1em 1em;
    font-size: 90%;
  }

  &-fat {
    font-size: 1.6rem;
  }

  &-green {
    background: $c-primary;

    &:not(.disabled):hover {
      background: lighten($c-primary, 4);
    }
  }

  &-red {
    background: $c-error;

    &:not(.disabled):hover {
      background: lighten($c-error, 11);
    }
  }

  &-link {
    @extend %button-none;

    color: $c-link;

    &:hover,
    &:active,
    &:focus {
      color: $c-link-hover;
    }
  }

  &.button-empty {
    transition: none;
    background: none;
    box-shadow: none;

    &,
    &:hover {
      color: $c-primary;
    }

    &:not(.disabled):hover {
      @extend %button-shadow;
    }

    &,
    &.button-green,
    &.button-red {
      &:not(.disabled):hover {
        @extend %metal;
      }
    }

    &.button-red,
    &.button-red:hover {
      color: $c-error;
    }

    &.button-green,
    &.button-green:hover {
      color: $c-primary;
    }
  }

  &.button-metal {
    @extend %metal;

    &,
    &:hover {
      color: $c-font-dim;
    }

    &:not(.disabled):hover {
      @extend %metal-hover;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.active {
    @extend %active;

    color: #fff;
  }

  &.active:not(.disabled):hover {
    @extend %active-hover;

    color: #fff;
  }
}
.button-set {
  @extend %flex-center;
  gap: 1.5em;
}
