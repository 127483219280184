%box-radius {
  @include box-radius;
}

%box-radius-force {
  @extend %box-radius;

  overflow: hidden;

  /* helps with clipping background into border-radius */
}

%box-radius-left {
  border-radius: $box-radius-size 0 0 $box-radius-size;
}

%box-radius-right {
  border-radius: 0 $box-radius-size $box-radius-size 0;
}

%box-radius-top {
  border-radius: $box-radius-size $box-radius-size 0 0;
}

%box-radius-bottom {
  border-radius: 0 0 $box-radius-size $box-radius-size;
}

%box-radius-top-left {
  border-radius: $box-radius-size 0 0 0;
}

%box-radius-top-right {
  border-radius: 0 $box-radius-size 0 0;
}

%box-radius-bottom-right {
  border-radius: 0 0 $box-radius-size 0;
}

%box-shadow {
  @include box-shadow;
}

%box-neat {
  @extend %box-shadow, %box-radius;
}

%box-neat-top {
  @extend %box-shadow, %box-radius-top;
}

%box-neat-force {
  //@extend %box-shadow,
  @extend %box-radius-force;
}
