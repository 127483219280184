.lpv {
  &__controls {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    user-select: none;
    background: $lpv-bg-controls;

    .lpv__fbt {
      flex: 1 1 auto;
      font-size: 1.4em;
      padding: 0.4em 0.7em;
      border-left: 1px solid $lpv-border;
      &:first-child {
        border: none;
      }
    }

    &__menu.lpv__fbt {
      flex: 0 1 auto;
      width: 4em;
      // the ellipsis icon is not well centered vertically
      padding: 0.45em 1em 0.35em 1em;
      font-size: 1.1em;
    }
    &__goto {
      padding: 0.4rem 0.7rem;
    }
  }
}
