$grid-side-column: fit-content(40%) !default;
$grid-side-column-full-screen: minmax(232px, 1fr) !default;
$grid-side-row: 6em !default;
$player-height: 2em !default;
$controls-height: 4em !default;
$moves-auto-to-right: 500px !default;
$board-min-width: 200px !default;

.lpv {
  display: grid;
  overflow: hidden;
  grid-row-gap: 0;

  --controls-height: #{$controls-height};
  &--controls-false {
    --controls-height: 0em;
  }

  &--moves-false {
    grid-template-areas:
      'board'
      'controls';
    grid-template-columns: minmax(200px, calc(100vh - var(--controls-height)));
    grid-template-rows: auto var(--controls-height);
  }

  &--moves-right {
    grid-template-areas:
      'board      side'
      'controls   side';
    grid-template-columns: auto $grid-side-column;
    grid-template-rows: auto var(--controls-height);
  }

  &--moves-bottom {
    grid-template-areas:
      'board'
      'controls'
      'side';
    grid-template-rows: auto var(--controls-height);
    .lpv__controls {
      border-bottom: 1px solid $lpv-border;
    }
  }

  &--moves-auto {
    grid-template-areas:
      'board      side'
      'controls   side';
    grid-template-columns: minmax($board-min-width, calc(100vh - var(--controls-height))) $grid-side-column-full-screen;
    grid-template-rows: auto var(--controls-height);
    @media (max-width: #{$moves-auto-to-right}) {
      grid-template-areas:
        'board'
        'controls'
        'side';
      grid-template-columns: minmax(
        $board-min-width,
        calc(100vh - var(--controls-height) - #{$grid-side-row})
      );
      grid-template-rows: auto var(--controls-height);
    }
  }

  &--players {
    &.lpv--moves-false {
      grid-template-areas:
        'player-top'
        'board'
        'player-bot'
        'controls';
      grid-template-rows: $player-height auto $player-height var(--controls-height);
    }

    &.lpv--moves-right {
      grid-template-areas:
        'player-top side'
        'board      side'
        'player-bot side'
        'controls   side';
      grid-template-rows: $player-height auto $player-height var(--controls-height);
    }

    &.lpv--moves-bottom {
      grid-template-areas:
        'player-top'
        'board'
        'player-bot'
        'controls'
        'side';
      grid-template-rows: $player-height auto $player-height var(--controls-height);
      .lpv__controls {
        border-bottom: 1px solid $lpv-border;
      }
    }

    &.lpv--moves-auto {
      grid-template-areas:
        'player-top side'
        'board      side'
        'player-bot side'
        'controls   side';
      grid-template-columns:
        minmax($board-min-width, calc(100vh - 2 * #{$player-height} - var(--controls-height)))
        $grid-side-column-full-screen;
      grid-template-rows: $player-height auto $player-height var(--controls-height);
      @media (max-width: #{$moves-auto-to-right}) {
        grid-template-areas:
          'player-top'
          'board'
          'player-bot'
          'controls'
          'side';
        grid-template-columns: minmax(
          $board-min-width,
          calc(100vh - 2 * #{$player-height} - var(--controls-height) - #{$grid-side-row})
        );
        grid-template-rows: $player-height auto $player-height var(--controls-height);
      }
    }
  }

  &__board {
    grid-area: board;
  }
  &__side {
    grid-area: side;
  }
  &__player--top {
    grid-area: player-top;
  }
  &__player--bottom {
    grid-area: player-bot;
  }
  &__controls {
    grid-area: controls;
  }
  &__menu,
  &__pgn {
    grid-area: 1 / 1 / 2 / 2;
    .lpv--players & {
      grid-area: 1 / 1 / 4 / 2;
    }
  }
}
