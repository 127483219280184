#promotion-choice {
  background: fade-out($c-bg-page, 0.3);
  z-index: z('cg__promotion');

  position: absolute;
  width: var(--cg-width, 100%);
  height: var(--cg-height, 100%);
  right: 10px;
  top: 10px;
  bottom: 10px;
  left: 10px;
  margin: auto;

  square {
    cursor: pointer;
    border-radius: 50%;
    background-color: #414eb3;
    border:  1px solid #4a5acc;

    @include transition;
  }

  piece {
    pointer-events: auto;
    opacity: 1;

    /* cancels blindfold */
  }

   piece {
    width: 100%;
    height: 100%;

    @include transition;

    transform: scale(0.8);
  }

  square:hover {
    background-color: rgb(93 112 255);
    //border-radius: 0%;
  }

  square:hover piece {
    transform: none;
  }
}
