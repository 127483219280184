.puzzle__side {
  &__metas {
    @extend %box-neat-force;

    background: $c-bg-box;
    padding: 2vmin;

    p {
      margin: 0;
    }


    .hidden {
      color: $c-font-dimmer;
    }

    .infos {
      @extend %flex-center-nowrap;

      overflow: hidden;
      white-space: nowrap;

      &::before {
        font-size: 3rem;
        margin-#{$end-direction}: 1vw;
      }

      &.puzzle {
        padding-bottom: 1.2vh;
        border-bottom: $border;
        margin-bottom: 1.2vh;
      }

      .players {
        margin-top: 0.3rem;
        font-size: 90%;
      }
    }
  }

  &__user {
    @extend %box-neat;

    background: $c-bg-box;
    padding: 2vmin;

    &__rating {
      strong {
        @extend %flex-center;
        justify-content: center;
        font-size: 3.5em;
      }
      &__casual {
        @extend %roboto;
        font-size: 0.9em;
        margin-top: 1em;
      }
    }

    .rp {
      font-size: 1.3rem;
      margin-#{$start-direction}: 1ch;

      &.up {
        color: $c-good;
      }

      &.down {
        color: $c-bad;
      }
    }
  }

  &__streak {
    &__info {
      h1 {
        color: $c-brag;
        @extend %flex-center-nowrap;
        font-size: 2.2rem;
        margin-bottom: 1rem;
        &::before {
          font-size: 1.5em;
        }
      }
    }

    &__score {
      color: $c-brag;
      @extend %flex-center-nowrap;
      justify-content: center;
      font-size: 4em;

      &::before {
        font-size: 0.9em;
      }
    }
  }

  &__config {
    @extend %box-neat;

    align-self: start;
    background: $c-bg-box;
    padding: 2vmin;
    overflow: hidden;

    &__toggle {
      @extend %flex-center-nowrap;

      .switch {
        margin-#{$end-direction}: 1em;
      }

      label {
        cursor: pointer;
      }
    }

    &__difficulty {
      margin-top: 2vmin;

      label {
        margin-#{$end-direction}: 1em;
      }

      select {
        border: none;
      }
    }

    &__toggles {
      @extend %flex-between;
      margin-top: 2vmin;
    }

    &__color {
      margin-top: 1.5vh;
      i {
        display: block;
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        padding: 0;
      }
      .label.active {
        background: mix($c-bg-box, $c-primary, 50%);
      }
    }
  }

  &__replay {
    @extend %box-neat;

    background: $c-bg-box;
    padding: 2vmin;

    a {
      @extend %roboto;

      font-size: 1.3em;
      margin-bottom: 1em;
      display: block;
    }

    &__bar {
      @extend %box-radius-force, %flex-center;

      position: relative;
      justify-content: center;
      padding: 0 1em;
      height: 2.5em;
      background: $c-bg-zebra2;
      font-weight: bold;

      &::before {
        @extend %box-radius;

        z-index: 0;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        #{$start-direction}: 0;
        width: var(--p);
        background: mix($c-bg-page, $c-primary, 40%)
        linear-gradient(180deg, rgba(255%, 255%, 255%, 0.15) 0%, transparent 35%);
        animation: bar-glider-anim 3s linear infinite;

        @include transition(width, 1s);
      }

      &::after {
        z-index: 1;
        content: attr(data-text);
      }
    }
  }

  &__theme {
    @extend %box-neat, %roboto;

    background: $c-bg-box;
    padding: 2vmin;
    font-size: 0.9em;

    h2 {
      font-size: 1.5em;
      margin-bottom: 0.6rem;
      text-transform: uppercase;
      &.long {
        font-size: 1.3em;
        letter-spacing: 0px;
      }
    }

    &--daily {
      outline: 3px solid mix($c-bg-page, $c-good, 50%);
      h2 {
        margin: 0;
        text-align: center;
      }
    }
  }
}

.puzzle__themes {
  padding-top: 1.2vh;
  border-top: $border;
  margin-top: 1.2vh;

  &__list {
    &__entry {
      @extend %flex-between;

      align-items: stretch;
      margin-#{$start-direction}: -0.5rem;

      a {
        margin-#{$start-direction}: 0.5rem;
        line-height: 2em;
      }

      &:hover {
        background: mix($c-bg-box, $c-link, 90%);
      }

      &.strike a {
        text-decoration: line-through;
      }
    }
  }

  &__votes {
    @extend %flex-center-nowrap;

    flex: 0 1 7em;
    align-items: stretch;
    text-align: center;
  }

  &__vote {
    @extend %flex-center;

    justify-content: center;
    flex: 0 0 50%;
    cursor: pointer;
    color: $c-font-dimmer;

    .puzzle__themes:hover & {
      color: $c-good;
    }

    .puzzle__themes:hover &.vote-down {
      color: $c-bad;
    }

    &::before {
      @extend %data-icon;

      content: '';
      font-size: 1.3em;
    }

    &.vote-down::before {
      transform: rotate(0.5turn);
    }

    &:hover,
    &.active {
      background: $c-good;
      color: $c-good-over !important;
    }

    &.vote-down:hover,
    &.vote-down.active {
      background: $c-bad;
    }
  }

  &__lock {
    @extend %flex-center;

    justify-content: center;
    flex: 0 0 100%;
    color: $c-font-dimmer;
    opacity: 0.5;
  }

  &__selector {
    margin: 1em 0;
  }

  &__study {
    display: block;
  }
}
