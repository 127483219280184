.puzzle__feedback.after {
  flex-flow: row nowrap;

  @include breakpoint($mq-col2) {
    flex-flow: column;
  }

  .continue {
    @extend %flex-center-nowrap;

    justify-content: center;
    font-size: 1.3em;
    background: $c-primary;
    color: $c-primary-over;
    text-transform: uppercase;
    padding: 2em;

    &:hover {
      background: lighten($c-primary, 10%);
    }

    i::before {
      font-size: 2.5em;
      margin-#{$end-direction}: 10px;
    }
  }

  .complete {
    @extend %flex-center;

    justify-content: center;
    flex: 1 1 50%;
    font-size: 1.3em;
    white-space: nowrap;

    .game-over {
      letter-spacing: 0.5ch;
      border-bottom: $border;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }

    .puzzle--streak & {
      @extend %flex-column;
    }
  }

  .puzzle__more {
    @extend %flex-between;

    display: none;
    border-top: $border;
    padding: 1em 2em;

    a {
      color: $c-font;

      &:hover {
        color: $c-link;
      }
    }

    @include breakpoint($mq-col2) {
      display: flex;
    }
  }
}

.puzzle__vote {
  @extend %flex-column;

  flex: 1 1 70%;

  &__help {
    margin: 0.5em 1em 0 0;

    p {
      display: inline;

      &:last-child {
        margin-#{$start-direction}: 1ch;
      }
    }

    @include breakpoint($mq-col2) {
      display: block;
      margin: 0 1em;
    }
  }

  &__buttons {
    @extend %flex-center-nowrap;
    flex: 1 1 100%;
    align-items: stretch;
    text-align: center;
    opacity: 0.1;

    &.enabled {
      opacity: 1;

      @include transition(opacity, 0.5s);
    }

  }

  .vote {
    @extend %flex-center;

    justify-content: center;
    flex: 0 0 50%;
    cursor: pointer;
    color: $c-good;

    &.vote-down {
      color: $c-bad;
    }

    &::before {
      @extend %data-icon;

      content: '';
      font-size: 5em;
    }

    &.vote-down::before {
      transform: rotate(0.5turn);
    }

    &:hover,
    &.active {
      background: $c-good;
      color: $c-good-over;
    }

    &.vote-down:hover,
    &.vote-down.active {
      background: $c-bad;
    }
  }
}
