.puzzle__session {
  display: flex;
  flex-flow: row wrap-reverse;
  margin-top: -0.4em;

  a {
    @extend %box-radius, %flex-center;

    flex: 0 0 2.3em;
    height: 1.6em;
    margin: 0.4em 0.5em 0 0;
    color: #fff;
    opacity: 0.85;
    font-size: 0.85em;
    font-weight: bold;
    justify-content: center;

    &.result-empty::before {
      @extend %data-icon;
      content: '';
      font-size: 0.9em;
    }

    &.result-true {
      background: $c-good;
    }

    &.result-false {
      background: $c-bad;

      &.result-empty::before {
        content: '';
      }
    }

    &.current {
      background: $c-brag;
    }

    &.session-new {
      background: $c-link;
    }

    &:hover {
      opacity: 1;
    }
  }
}
