.cmn-toggle {
  @extend %double-tap;
  position: absolute;
  margin-#{$start-direction}: -99999px;
}

.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  padding: 1px;
  width: 34px;
  height: 18px;
  //border: 1px solid $c-border;
  border-radius: 24px;

  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    top: 3px;
    bottom: 0;
    #{$start-direction}: 0;
  }


  &::after {
    background: white;
    border-radius: 100%;
    //box-shadow: 0 1px 2.5px rgba(0, 0, 0, 0.3);
  }
}

.cmn-toggle:not(:checked) + label {
  background-color: $c-bad;
}

.cmn-toggle--subtle:not(:checked) + label {
  background-color: $c-font-dimmer;
}

.cmn-toggle:checked + label {
  background-color: #5D70FF;
}

.cmn-toggle:disabled + label {
  opacity: 60%;
  cursor: not-allowed !important;
}

.cmn-toggle:focus:not(:disabled) + label {
  @extend %focus-shadow;
}

.cmn-toggle:hover:not(:disabled) {
  + label {
    //@extend %focus-shadow;
    @include transition(background);


    &::after {
      @if $theme == 'transp' {
        background: linear-gradient(
          to bottom,
          hsl($c-site-hue, 7%, 25),
          hsl($c-site-hue, 5%, 22) 100%
        );
      } @else {
        @extend %metal;
      }
      @include transition(margin);
    }
  }
}

.cmn-toggle--subtle:not(:checked) + label {
 &::after {
    margin-left: 3px;
  }
}

.cmn-toggle:checked + label {
  &::after {
    margin-#{$start-direction}: 19px;
  }
}
