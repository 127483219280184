.ceval {
  @extend %metal-bg;

  position: relative;
  display: flex;
  align-items: stretch;
  color: $c-font;

  &.enabled {
    padding-top: 2px;
    //height: 40px;

    .show-threat {
      visibility: visible;
    }
  }

  .switch {
    @extend %flex-center;
    padding: 12px 16px 8px 8px;
    flex: 0 0 40px;
    //margin-#{$end-direction}: 8px;
  }

  pearl {
    flex: 0 0 75px;
    line-height: 38px;
    font-size: 1.6em;
    text-align: center;
    font-weight: bold;
  }

  .is-red::before {
    vertical-align: -3px;
  }

  help,
  .engine {
    @extend %nowrap-hidden;
    padding: 12px 8px 8px 16px;
    flex: 1 1 auto;
    font-size: 14px;
    //line-height: 16px;
    //margin-top: 3px;
    color: #6B7280;
  }

  .engine {
    .cloud {
      @extend %roboto, %box-radius;

      margin-#{$start-direction}: 4px;
      background: $c-primary-dim;
      color: $c-primary-over;
      padding: 1px 3px;
      text-transform: uppercase;
      font-size: 11px;
    }

    .info {
      display: block;
      white-space: nowrap;
    }

    .deeper {
      color: $c-primary;
      margin-#{$start-direction}: 4px;
    }

    .deeper::before {
      vertical-align: -2px;
    }
  }

  .technology {
    margin-#{$start-direction}: 5px;
    &.good {
      color: $c-good;
    }
  }

  .bar {
    position: absolute;
    width: 100%;
    height: 3px;
    top: 0px;
  }

  @keyframes bar-anim {
    from {
      background-position: 0 0;
    }

    to {
      background-position: 100000px 0;
    }
  }

  .bar span {
    display: block;
    height: 3px;
    width: 0;
    background: $c-good;
    transition: width 1s;

    &.threat {
      background: $c-bad;
    }
  }

  &.computing .bar span {
    // background-image: img-url('bar-highlight.png');
    animation: bar-anim 1000s linear infinite;
  }

  .show-threat {
    flex: 0 99 auto;
    line-height: 38px;
    color: $c-font-dim;
    font-size: 0.9em;
    padding-#{$end-direction}: 0.5em;
    overflow: hidden;
    visibility: hidden;

    &:hover:not(.hidden),
    &.active {
      color: $c-red;
    }

    &.hidden {
      opacity: 0.3;
      cursor: default;
    }
  }
}
