.main-board {
  @extend %square;

  direction: ltr;

  &__preload {
    position: absolute;
  }

   .cg-wrap {
     //padding-bottom: 0;
     //height: 100%;
     //width: auto;
     //@extend %abs-100;
   }
}

.mini-board,
.mini-game .cg-wrap {
  @extend %square;
}
