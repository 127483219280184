.puzzle__tools {
  @extend %box-neat-force, %flex-column;

  overflow: visible;
  background: $c-bg-box;
  min-width: 200px;
  .ceval-wrap {
    flex: 0 0 38px;
  }

  .ceval {
    //height: 38px;
    //padding: 12px 16px;
    border-top: 1px solid #E5E7EB;
    background: transparent !important;
  }
}

.puzzle__moves {
  padding: 8px 0;
  flex: 2 1 auto;
  overflow: auto;
  border-top: 1px solid #E5E7EB;
  position: relative;

  /* required by line::before */

  .tview2 > move {
    justify-content: space-between;

    glyph {
      font-size: 1.6em;
    }
  }

  move {
    &.fail glyph {
      color: $c-bad;
    }

    &.good glyph,
    &.win glyph {
      color: $c-good;
    }

    &:hover glyph {
      color: #fff;
    }
  }
}

.puzzle__feedback {
  @extend %flex-column;

  flex: 1 0 9rem;
  background: $c-bg-zebra;
  justify-content: center;

  @include breakpoint($mq-col2) {
    @include breakpoint($mq-tall) {
      flex: 1 0 17rem;
    }
  }


  &:not(.after) {
    padding: 2em;
  }

  .player {
    display: flex;
    align-items: center;
  }

  .no-square {
    flex: 0 0 64px;
    height: 64px;
    margin-#{$end-direction}: 10px;
  }

  .is3d & div.no-square {
    height: 82px;
  }

  piece {
    position: inherit;
    display: block;
    width: 100%;
    height: 100%;
  }

  .icon {
    display: block;
    width: 64px;
    height: 64px;
    font-size: 50px;
    line-height: 64px;
    text-align: center;
  }

  &.good .icon,
  .win .icon {
    color: $c-good;
  }

  &.fail .icon {
    color: $c-bad;
  }

  .instruction > * {
    display: block;
  }

  .instruction strong {
    font-size: 1.5em;
  }

  .view_solution {
    margin-top: 1.5em;
    text-align: center;
    visibility: hidden;
    opacity: 0;
  }

  .view_solution.show {
    visibility: visible;
    opacity: 0.8;

    @include transition(opacity, 1.5s);
  }
}

.puzzle__controls {
  @extend %flex-center;

  height: $col3-uniboard-controls;
  margin-top: #{-$block-gap};

  @include breakpoint($mq-col1) {
    height: $col1-uniboard-controls;
  }

  // height: calc(#{$puzzle-history-height} + #{$block-gap});
  .jumps {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    button {
      margin-right: 20px;
      background-color: transparent;
    }
  }

  .fbt {
    @extend %page-text;
  }

  .glowing {
    color: $c-font-clearer;
  }
}
