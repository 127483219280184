.pv_box {
  position: relative;
  background: $c-bg-box;
  font-size: 13px;

  .pv-board {
    width: 300px;
    height: 300px;
  }
  .pv.line {
    display: flex;
    justify-content: center;
    padding: 2px 8px;
    border-top: 1px solid #f0f0f0;
    .pv {
      width: 300px;
      & .pv-wrap-toggle {
        transform: rotate(90deg);
      }
      &:not(.pv--nowrap) .pv-wrap-toggle {
        transform: rotate(-90deg);
      }
    }


    //border-top: 1px;
  }
  .pv.line:last-child {
    margin-bottom: 8px;
  }

  .pv {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    line-height: 2em;
    //border-top: $border;
    padding-#{$end-direction}: 20px;
    min-height: 2em;
    color: #A1A5B9;
    strong {
      color: #3e475b;
      margin-right: 6px;
    }
    .pv-san {
      color: #3e475b;
    }

    &.pv--nowrap {
      display: block; // "flex" doesn't support ellipsis so switch back to "block"
      flex-wrap: initial;
      height: 2em;
      @extend %nowrap-ellipsis;
    }

    &[data-uci]:hover {
      //background: mix($c-primary, $c-bg-box, 20%);
      cursor: pointer;
    }

    strong {
      display: inline-block;
      width: 34px;
      text-align: center;
    }

    span {
      @extend %san;

      margin-#{$start-direction}: 4px;
    }

    &[data-uci] .pv-san:hover {
      color: $c-primary;
    }

    .pv-wrap-toggle {
      position: absolute;
      top: 0;
      #{$end-direction}: 0;
      padding: 0 4px;
      margin: 0;
      cursor: pointer;

      &:hover {
        //background: $c-primary;
        //color: $c-primary-over;
        color: $c-primary;
      }
      &::before {
        content: '\279C';
      }
    }
  }

  .pv-board {
    @extend %popup-shadow;
    position: absolute;
    //width: 80%;
    #{$start-direction}: 34px;
    //max-width: 240px;
    z-index: 1;

    cg-board {
      width: 300px;
      height: 300px;
    }

    .pv-board-square {
      @extend %square;

      .cg-wrap {
        @extend %abs-100;
      }
    }
  }
}
